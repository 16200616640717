let accordionHeadings = document.querySelectorAll(".accordion-heading");
let accordionHeadingsLength = accordionHeadings.length;

function accStateChange() {
  if (accordionHeadings != undefined) {
    for (let i = 0; i <= accordionHeadingsLength; i++) {
      $(".accordion-heading-" + i).on("click", function () {
        $(".accordion-heading-" + i).toggleClass("accordion-heading-active");
        $(".accordion-heading")
          .not(".accordion-heading-" + i)
          .removeClass("accordion-heading-active");
        $(".accordion-body")
          .not(".accordion-body-" + i)
          .slideUp();
        $(".accordion-body-" + i).slideToggle();
      });
    }
  }
}
export { accStateChange };

function accViewPort() {
  if (accordionHeadings != undefined) {
    for (let i = 0; i <= accordionHeadingsLength; i++) {
      $(".accordion-heading-" + i).on("click", function (e) {
        let $card = $(this).closest(".accordion-heading");
        let $open = $($(this).data("parent")).find(
          ".accordion-heading.accordion-heading-active"
        );

        let additionalOffset = 350;
        if (
          $card.prevAll().filter($open.closest(".accordion-heading")).length !==
          0
        ) {
          additionalOffset = $open.height();
        }
        $("html,body").animate(
          {
            scrollTop: $card.offset().top - additionalOffset,
          },
          500
        );
      });
    }
  }
}
export { accViewPort };

function accURLOpener() {
  if (accordionHeadings != undefined) {
    let accordionAnchor = window.location.hash.substr(2);
    let accordionID = document.getElementById(accordionAnchor);
    if (accordionID != undefined) {
      let accordionHeading =
        accordionID.getElementsByClassName("accordion-heading");

      accordionHeading[0].click();
    }
  }
}

export { accURLOpener };
