function toggleNavigation() {

  $("#menu-main li.children button").click(function (event) {
    event.preventDefault();
    $(this).toggleClass("open");
    $(this).next().toggleClass("open");
  });

  $('#menu-main li.children a[href="#"]').click(function (event) {
    event.preventDefault();
    $(this).next().toggleClass("open");
    $(this).next().next().toggleClass("open");
  });

  $('.navbar-toggler').click(function (event) {
    event.preventDefault();
    $(this).toggleClass("open");
    $('.menu-wrapper').toggleClass("open");

  });

  let currentLocation = window.location.href;
  let menuChildren = $('#menu-main').children().children('a');


  if (currentLocation != null && currentLocation.indexOf('/blog/') >= 0){

    menuChildren.each(function(){
      if (this.getAttribute("href").indexOf('/blog/') >= 0) {
        $(this).parent().addClass('active');
      } else{
        $(this).parent().removeClass('active');
      }
    });

  } else{

    menuChildren.each(function(){
      if (this.getAttribute("href").indexOf('/blog/') >= 0) {
        $(this).parent().removeClass('active');
      }
    });

  }
}
export { toggleNavigation };
