function formShowBlocks() {
    $('#grund_normal').on('click', function () {
        $("#grund_sonder_auswahl").hide();
    });
    $('#grund_sonder').on('click', function () {
        $("#grund_sonder_auswahl").show();
    });
    $('input[name="auswahl_sonder"]').on('click', function () {
        if (this.id=="auswahl_umzug") {
            $("#umzug-inputs").show();
        }
        else{
            $("#umzug-inputs").hide();
        }
        if (this.id=="auswahl_sonstiges") {
            $("#sonstiges-nachricht-block").show();
        }
        else{
            $("#sonstiges-nachricht-block").hide();
        }
    });
    $("#download-button-cancel").on('click', function () {
      // $("#grund_sonder_auswahl").hide();

        console.log("downloadtest");
        let url=this.dataset.link;
        if (url!=undefined) {
            $.ajax({
                url:"/wp-content/themes/bergauf/blocks/cancelform/ajax/fakedownload.php",
                method: "POST",
                data: {
                    url: url,
                    methode:"generate"
                },
            }).done(function(data)
                {
                    console.log(data);
                    var linkNeu=window.location.origin+"/wp-content/themes/bergauf/blocks/cancelform/ajax/";
                    var link = document.createElement('a');
                    link.href = linkNeu+data;
                    link.download = url;
                    link.dispatchEvent(new MouseEvent('click'));
                    $.ajax({
                        url:"/wp-content/themes/bergauf/blocks/cancelform/ajax/fakedownload.php",
                        method: "POST",
                        data: {
                            url: url,
                            methode:"delete"
                        },
                    }).done(function(data)
                    {
                        console.log(data);
                    }).fail(function(data){
                        console.log(data);
                    });
            })
            .fail(function(data){
                console.log(data);
            });
        }
    });
}

export { formShowBlocks };
