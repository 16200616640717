/* General + Third-Party */
import jQuery from 'jquery';
import { lazyload } from './globals/lazyload';

/* Layout-Parts */
import { toggleNavigation } from './layout/header';

/* Blocks */
import { accStateChange } from '../../blocks/accordion/script';
import { accViewPort } from '../../blocks/accordion/script';
import { accURLOpener } from '../../blocks/accordion/script';
import { formShowBlocks } from '../../blocks/cancelform/script';
import { iframeResize } from '../../blocks/iframe/script';

jQuery(document).ready(_ => {
  // General + Third-Party
  lazyload();

  //Layout-Parts
  toggleNavigation();

  //Blocks
  accStateChange();
  accViewPort();
  accURLOpener();
  formShowBlocks();
  iframeResize();

});

