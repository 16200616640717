export function iframeResize() {
    window.onload = function() {
        $('#bestandskunden').css('min-height', '333px');
        function receiveSize(e) {
            if (e.origin === "https://kundenportal.enno-energie.de")
                $('#bestandskunden').attr('height', e.data);
            var b = $('#bestandskunden').height();
            $('#bestandskunden').height(e.data);
        }
        if (window.addEventListener){
            window.addEventListener("message", receiveSize, false);
        } else {
            window.attachEvent("onmessage", receiveSize);
        }
    
        //*
        var sHref = window.self.location.href;
        if ( sHref.match(/\?/) ) {
            var sGET = sHref.replace(/(.*)\?(.*)$/, '$2');
            if ( '' != sGET ) {
                var ifrm = document.getElementById("bestandskunden");
                ifrm.src = ifrm.src + '?' + sGET + '&iframe=1';
            }
        }
        //*/
    }
}